import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CrearArchivo, ParamsConsult, Stage } from '../modules/inbox/components/inbox/inbox.component';
import { ArchivoBanamex } from '../modules/review/components/review/review.component';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class ConsumeService {

    headers = new HttpHeaders().set("Content-Type","application/json");

    constructor(private _http: HttpClient
    
  ) { }

//Access-Control-Allow-Origin 

  obtenerDetalle(paramsConsult: ParamsConsult ): Observable<any> { 
    return this._http
      .get(environment.apigateway+'/bandeja/detalle?Fecha='+paramsConsult.fecha+'&Estado='+paramsConsult.estado+'&NPagina='+paramsConsult.pagina+'&NRegistros='+paramsConsult.registros+'&idProceso='+paramsConsult.idProceso+'&ClaveRetiro='+paramsConsult.claveRetiro)
      .pipe(catchError(this.handleError));
  }


  handleError(error: any) {
    return throwError(error || 'Server error');
  }

  obtenerPagos(paramsConsult: ParamsConsult): Observable<any> {
      return this._http
      .get(environment.apigateway+'/bandeja?FechaIni='+paramsConsult.fecha+'&Estado='+paramsConsult.estado+'&NPagina='+paramsConsult.pagina+'&NRegistros='+paramsConsult.registros+'&FechaFin='+paramsConsult.fecha)
      .pipe(catchError(this.handleError));
  }

  actualizarEstatusPago(stage: Stage[]): Observable<any> {
    return this._http
      .post(environment.apigateway+'/pago', stage, { responseType: 'json' })
      .pipe(catchError(this.handleError));
  }

  /*actualizarEstatusPago(clave: any): Observable<any> {
    return this._http
      .get(environment.apigateway+'/pago?claveRetiro='+clave)
      .pipe(catchError(this.handleError));
  }*/

  obtenerCatalogos(): Observable<any> {
    return this._http
      .get(environment.apigateway+'/catalogo')
      .pipe(catchError(this.handleError));
  }

  obtenerEstadistica(fechaInicio:string, fechaFin:string){
    return this._http
      .get(environment.apigateway+'/estadistica?FechaIni='+fechaInicio+'&FechaFin='+fechaFin)
      .pipe(catchError(this.handleError));
  }

  crearArchivosPension(clavesEnviar: CrearArchivo[]): Observable<any> {
    return this._http
      .post(environment.apigateway+'/respuesta/archivo', clavesEnviar, { responseType: 'json' })
      .pipe(catchError(this.handleError));
  }

  enviarArchivosBanamex(archivosBanamex: ArchivoBanamex[]): Observable<any> {
    return this._http
      .post(environment.apigateway+'/subir/archivo', archivosBanamex, { responseType: 'json' })
      .pipe(catchError(this.handleError));
  }
  
}