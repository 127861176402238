import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Bandeja } from '../../inbox/components/inbox/inbox.component';

@Component({
  selector: 'app-confirm-revision',
  templateUrl: './confirm-revision.component.html',
  styleUrls: ['./confirm-revision.component.scss']
})
export class ConfirmRevisionComponent implements OnInit {

  dataSource = new MatTableDataSource<Bandeja>();
  displayedColumns: string[] = ['cuenta', 'clave', 'monto'];
  status:string = '';

  constructor(
    public dialog: MatDialogRef<ConfirmRevisionComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) {
      this.dataSource = new MatTableDataSource<Bandeja>(data.pagos);
      this.status = data.status;
    }
    ngOnInit(): void {
    }

    close(aceptar:any){
      this.dialog.close(aceptar);
    }

}
