import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsumeService } from 'src/app/services/consume.services';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmRevisionComponent } from 'src/app/modules/modals/confirm-revision/confirm-revision.component';
import { ErrorComponent } from 'src/app/modules/modals/error/error.component';
import { DataBaseService } from 'src/app/services/database.service';
import { ModifyCountEmmiter } from './modify-count-emmiter';
import { ConfirmComponent } from 'src/app/modules/modals/confirm/confirm.component';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit {

  retiros = new FormControl('');
  retiroList: Catalogo[] = [];
  depositos = new FormControl('');
  depositosList: Catalogo[] = [];
  cuentaRetiro = new FormControl('');
  cuentaRetiroList: Catalogo[] = [];
  bandejaFull: Bandeja[] = [];
  total: Bandeja[] = [];
  totalFinal: Bandeja[] = [];
  filtroMontoCuenta = "";
  selection = new SelectionModel<Bandeja>(true, []);
  detallePeticion : DetalleBandeja = {
    Tot_Regs: '',
    Total_Opr: '',
    Total_SPEI: '',
    Total_7699: '',
    Total_8004: '',
    Tot_General: ''
  }; 
  searchForm = this.fb.group({
    nameFilter: ['']
  });

  bodyToken: bodyToken;
  @ViewChild(MatPaginator) paginator : MatPaginator;
  isLoading: boolean = true;
  

  constructor(public route: ActivatedRoute,
    private router: Router,
    private consumeService: ConsumeService,
    private fb: FormBuilder,
    public dialog:MatDialog, public dataBase:DataBaseService, public totalEmmit:ModifyCountEmmiter,
    private utils: UtilsService) {
    }

  ngOnInit(): void {
    this.totalEmmit.ejecutaAccionMenus.emit('Inbox');
    localStorage.setItem('tabIndexSet', '0');
    this.isLoading = true;
    this.invokeBandeja();
    this.dataBase.obtenerCatalogos()
    .subscribe( data => {
      if(data!== undefined){
        this.retiroList = data.value.CatalogoRetiros;
        this.depositosList = data.value.CatalogoTiposPago;
        this.cuentaRetiroList = data.value.CatalogoCuentasRetiro;
      }
    }, err => console.error(err) );
  }

  

  invokeBandeja(){
    this.selection.clear();
    let busqueda = this.search('B');
    this.consumeService.obtenerPagos(busqueda)
    .subscribe( data => {
      this.detallePeticion.Tot_Regs = data.Tot_Regs;
      this.detallePeticion.Total_Opr = data.Total_Opr;
      this.detallePeticion.Total_SPEI = data.Total_SPEI;
      this.detallePeticion.Total_7699 = data.Total_7699;
      this.detallePeticion.Total_8004 = data.Total_8004;
      this.detallePeticion.Tot_General = data.Tot_General;

      this.bandejaFull = this.generateData(data.ResumenResponses);
      this.dataSourceBackUp = this.bandejaFull;
      this.dataSourceAll = new MatTableDataSource<Bandeja>(this.bandejaFull);
      this.dataSourceAll.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
       }
      );
  }
  getValuesCustom(){
    let valor : any; 
    this.getValues(valor,'');
  }
  getValues(event: {
    isUserInput: any;
    source: { value: any; selected: any };
  }, tipo: String) {
    
    this.bandejaFull = this.dataSourceBackUp;
    this.total = [];
    
    if(tipo === 'retiro'){
      if (event.isUserInput) {
        if (event.source.selected === true) {
          this.bF.push(event.source.value.Id);
        } else {
          const index: number = this.bF.indexOf(event.source.value.Id);
          if (index !== -1) {
              this.bF.splice(index, 1);
          }   
        }
      }
    }

    if(tipo === 'deposito'){
      if (event.isUserInput) {
        if (event.source.selected === true) {
          this.bFtipoPago.push(event.source.value.Desc);
        } else {
          const index: number = this.bFtipoPago.indexOf(event.source.value.Desc);
          if (index !== -1) {
              this.bFtipoPago.splice(index, 1);
          }   
        }
      }
    }

    if(tipo === 'cuenta'){
      if (event.isUserInput) {
        if (event.source.selected === true) {
          this.bFcuentaRetiro.push(event.source.value.Id);
        } else {
          const index: number = this.bFcuentaRetiro.indexOf(event.source.value.Id);
          if (index !== -1) {
              this.bFcuentaRetiro.splice(index, 1);
          }   
        }
      }
    }

    this.bandejaFull.forEach((item) => {
      this.bFtipoPago.forEach(e => {
        let cantTipoPago = [];
        if (item.Tipo_Pago === e) {
          cantTipoPago = this.total.filter(
            (interno) => interno.Tipo_Pago == e && item.Clave === interno.Clave && item.Tipo_Retiro === interno.Tipo_Retiro
          );
          if(cantTipoPago.length <= 0){
            this.total.push(item);
          }
        } 
      });
      this.bF.forEach(e => {
        let cantBf = [];
        if (item.Id_Proceso === e) {
          cantBf = this.total.filter(
            (interno) => interno.Id_Proceso == e && item.Clave === interno.Clave && item.Tipo_Retiro === interno.Tipo_Retiro
          );
          if(cantBf.length <= 0){
            this.total.push(item);
          }
        } 
      });
      this.bFcuentaRetiro.forEach(e => {
        let cantCuenRet = [];
        if (item.Cuenta_Retiro === e) {
          cantCuenRet = this.total.filter(
            (interno) => interno.Cuenta_Retiro == e && item.Clave === interno.Clave && item.Tipo_Retiro === interno.Tipo_Retiro
          );
          if(cantCuenRet.length <= 0){
            this.total.push(item);
          }
        } 
      });
    });
    
    this.totalFinal = [];
    this.total.forEach(val => this.totalFinal.push(Object.assign({}, val)));


    if(this.total.length > 0 && this.bFtipoPago.length > 0){
      let a = 0;
      this.total.filter((item) => {
        let borrar = true;
        this.bFtipoPago.forEach(e => {
          if (item.Tipo_Pago === e) {
            borrar = false;
          } 
        });
        if(borrar){
          const index: number = this.totalFinal.map(e => e.Tipo_Pago).indexOf(item.Tipo_Pago);
          this.totalFinal.splice(index, 1);
        }
        a++;
      });
    }

    if(this.total.length > 0 && this.bF.length > 0){
      let a = 0;
      this.total.filter((item) => {
        let borrar = true;
        this.bF.forEach(e => {
          if (item.Id_Proceso === e) {
            borrar = false;
          } 
        });
        if(borrar){
          const index: number = this.totalFinal.map(e => e.Id_Proceso).indexOf(item.Id_Proceso);
          this.totalFinal.splice(index, 1);
        }
        a++;
      });
    }

    if(this.total.length > 0 && this.bFcuentaRetiro.length > 0){
      let a = 0;
      this.total.filter((item) => {
        let borrar = true;
        this.bFcuentaRetiro.forEach(e => {
          if (item.Cuenta_Retiro === e) {
            borrar = false;
          } 
        });
        if(borrar){
          const index: number = this.totalFinal.map(e => e.Cuenta_Retiro).indexOf(item.Cuenta_Retiro);
          this.totalFinal.splice(index, 1);
        }
        a++;
      });
    }

    if(this.bF.length === 0 && this.bFcuentaRetiro.length === 0 && this.bFtipoPago.length === 0){
      this.totalFinal = this.dataSourceBackUp;
    }
    
    //Validar monto o clave
    if(this.searchForm.controls.nameFilter.value !== ''){
      this.totalFinal = this.totalFinal.filter((item) => 
        item.Clave === this.searchForm.controls.nameFilter.value || item.Monto_Total.toString() === this.searchForm.controls.nameFilter.value
    );
    }

    this.dataSourceAll = new MatTableDataSource<Bandeja>(this.totalFinal);
    this.bandejaFull = this.totalFinal;
    this.dataSourceAll.paginator = this.paginator;
  }

  generateData(data: any): Bandeja[] {
    const x: Bandeja[] = [];
    data.forEach((e: any) => {
      x.push({
        Tipo_Pago: e.Tipo_Pago,
        Cuenta_Retiro: e.Cuenta_Retiro,
        Clave: e.Clave,
        Tipo_Retiro: e.Tipo_Retiro,
        Monto_Total: e.Monto_Total,
        Num_Operaciones: e.Num_Operaciones,
        Id_Proceso: e.Id_Proceso,
        Fecha: e.Fecha,
        Status_Op: e.Status_Op,
        Aprobador_1: e.Aprobador_1,
        Aprobador1_Nombre: e.Aprobador1_Nombre,
        Aprobador_2: e.Aprobador_2,
        Aprobador2_Nombre: e.Aprobador2_Nombre,
        Observaciones: e.observaciones,
        Op_Aprobadas: e.Op_Aprobadas,
        Monto_Operaciones_Aplicadas: e.Monto_Operaciones_Aplicadas,
        Op_Rechazadas: e.Op_Rechazadas,
        Monto_Operaciones_Rechazadas: e.Monto_Operaciones_Rechazadas,
        XML_Status_Envio: ''
      });
    });
    return x;
  }

  displayedColumns: string[] = ['select', 'tipo', 'cuenta', 'tipoRetiro', 'clave', 'operaciones', 'monto', 'button'];
  dataSourceAll = new MatTableDataSource<Bandeja>();
  dataSourceBackUp: Bandeja[] = [];
  bF: String[] = [];
  bFtipoPago: String[] = [];
  bFcuentaRetiro: String[] = [];

  showDetail(renglon:any): void{
    this.router.navigate(['inbox/detail'], { state: { hello: renglon, status:'B', etapa:'0/2' } });
  }

  enviarRevision(){
    var elements : Bandeja[] = this.selection.selected;

    if(elements.length <= 0){
      this.dialog.open(ErrorComponent,{data:{error:"Seleccione al menos un registro para enviar a revisión."}});
    }else{
      this.isLoading = true;
      const dialogRef = this.dialog.open(ConfirmRevisionComponent,{data:{pagos:this.selection.selected, status: 'En Revision'}});

      dialogRef.afterClosed().subscribe(result =>{
        if(result){
          const x: Stage[] = [];
          let fechaActual = this.utils.getActualDate();
          elements.forEach( element =>{
            x.push({
              fecha: fechaActual,
              claveRetiro: element.Clave,
              tipoStatus: 'ETAPA',
              status: 'ER',
              observaciones: '',
              usuario: '',
              usuarioNm: ''
            });
          });
          this.consumeService.actualizarEstatusPago(x).subscribe( data => {
            if(data.status !== undefined){
                this.dialog.open(ErrorComponent,{data:{error:"Error al enviar a revision." +JSON.stringify(data.errors)}});
            }else{
              this.dialog.open(ConfirmComponent,{data:{error:"Se envio a revision su(s) pago(s)"}});
            }
            this.totalEmmit.ejecutaAccionMenus.emit('Inbox');
            this.invokeBandeja();
          }, err => {
            this.isLoading = false;
          });
        }else{
          this.isLoading = false;
        }
      });
    }      
  }

  search(status:any): ParamsConsult {
    let fechaActual = this.utils.getActualDate();
    const x: ParamsConsult = {
      fecha: fechaActual,
      estado: status,
      pagina: '1',
      registros: '200',
      idProceso: '',
      claveRetiro: ''
    };
    return x;
  }
}

export interface Review {
  tipo: string;
  cuenta: number;
  clave: string;
  tipoRetiro: string;
  monto: string;
  operaciones: number;
}

export interface bodyToken{
  client_id:string,
  client_secret:string,
  scrope:string,
  grant_type:string
}

export interface Bandeja {
  Tipo_Pago: string;
  Cuenta_Retiro: string;
  Clave: string;
  Tipo_Retiro: string;
  Monto_Total: string;
  Num_Operaciones: string;
  Id_Proceso: string;
  Fecha: string;
  Status_Op: string;
  Aprobador_1: string;
  Aprobador1_Nombre: string;
  Aprobador_2: string;
  Aprobador2_Nombre: string;
  Observaciones: string;
  Op_Aprobadas: string;
  Monto_Operaciones_Aplicadas: string;
  Op_Rechazadas: string;
  Monto_Operaciones_Rechazadas: string;
  XML_Status_Envio: string;
}

export interface Catalogo{
  Id: string;
  Desc: string;
}

export interface ParamsConsult{
  fecha: string;
  estado: string;
  pagina: string;
  registros: string;
  idProceso: string;
  claveRetiro: string;
}

export interface DetalleBandeja {
  Tot_Regs: string;
  Total_Opr: string;
  Total_SPEI: string;
  Total_7699: string;
  Total_8004: string;
  Tot_General: string;
}

export interface Stage {
  fecha: string;
  claveRetiro: string;
  tipoStatus: string;
  status: string;
  observaciones: string;
  usuario: string;
  usuarioNm: string;
}

export interface CatalogTable{
  id:Number;
  name:string;
  value:any;
}

export interface UserTable{
    id:Number;
    user:UserDetail;
}

export interface UserDetail{
  username:string;
}

export interface CrearArchivo{
  fecha:string;
  claveRetiro:string;
}

export interface SessionDetail{
  refresh:string;
  token:string;
}