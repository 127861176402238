import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LogoutConfirmationComponent } from '../../../logout/components/logout-confirmation/logout-confirmation.component';
import { ConsumeService } from 'src/app/services/consume.services';
import { ModifyCountEmmiter } from 'src/app/modules/inbox/components/inbox/modify-count-emmiter';
import { ParamsConsult } from 'src/app/modules/inbox/components/inbox/inbox.component';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  styleNav: boolean = false;
  mobileQuery: MediaQueryList;
  changeCss: boolean= true;
  name: string = '';
  initial: string = '';
  countEntrada: string = '';
  countReview: string = '';
  countEnviado: string = '';

  navMenu = [
    {name: "Bandeja de Entrada", route: "inbox", count: this.countEntrada},
    {name: "En revisión", route: "review", count: this.countReview},
    // {name: "Enviar", route: "send"},
    {name: "Respuesta", route: "response", count: this.countEnviado},
    {name: "Estadísticas", route: "statistics"}
  ];

  


  constructor(media: MediaMatcher, public dialog: MatDialog,
    private consumeService: ConsumeService, public inbox:ModifyCountEmmiter,
    private utils: UtilsService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)')
  }

  ngOnInit(): void {
    let attr = JSON.parse(localStorage.getItem('userCustom'));
    this.name = attr.given_name + ' '+ attr.family_name;
    this.initial = attr.given_name.substring(0,1) + attr.family_name.substring(0,1);
    this.countBandeja();
    this.inbox.ejecutaAccionMenus.subscribe(total => {
      this.countBandeja();
    })
  }

  change() {
    this.styleNav = true;
  }

  changeStyle() {
    this.changeCss = !this.changeCss;
  }

  logoutConfirmation() {
    const dialogRef: MatDialogRef<LogoutConfirmationComponent> = this.dialog.open(LogoutConfirmationComponent, {
      width: '40%'
    });
  }

  countBandeja(){
    let busqueda = this.search('B');
    this.consumeService.obtenerPagos(busqueda)
    .subscribe( data => {
      this.countEntrada = data.Tot_Regs;
    }, err => {
      console.error(err); }
    );

    this.consumeService.obtenerPagos(this.search('ER'))
    .subscribe( data => {
      this.countReview = data.Tot_Regs;
    }, err => {
      console.error(err); }
    );

    this.consumeService.obtenerPagos(this.search('E'))
    .subscribe( data => {
      this.countEnviado = data.Tot_Regs;
    }, err => {
      console.error(err); }
    );
  }

  search(status:any): ParamsConsult {
    let fechaActual = this.utils.getActualDate();
    const x: ParamsConsult = {
      fecha: fechaActual,
      estado: status,
      pagina: '1',
      registros: '200',
      idProceso: '',
      claveRetiro: ''
    };
    return x;
  }

}
