<form [formGroup]="searchForm" class="ds-flex-around banner-filter ">
    <div class="ds-flex-column w-22">
        <mat-label class="font-16 text-white">Proceso / Tipo de retiro</mat-label>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="retiros" multiple>
                <mat-option *ngFor="let retiro of retiroList" [value]="retiro" (onSelectionChange)="getValues($event,'retiro')">{{retiro.Desc}} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="ds-flex-column w-15">
        <mat-label class="font-16 text-white">Tipo de pago</mat-label>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="depositos" multiple>
                <mat-option *ngFor="let deposito of depositosList" [value]="deposito" (onSelectionChange)="getValues($event,'deposito')">{{deposito.Desc}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="ds-flex-column w-15">
        <mat-label class="font-16 text-white">Cuenta retiro</mat-label>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="cuentaRetiro" multiple>
                <mat-option *ngFor="let cuenta of cuentaRetiroList" [value]="cuenta" (onSelectionChange)="getValues($event,'cuenta')">{{cuenta.Desc}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="ds-flex-column w-23">
        <mat-label class="font-16 text-white">Clave o Monto</mat-label>
        <mat-form-field class="example-full-width pointer">
            <input type="text" matInput formControlName="nameFilter" (blur)="getValuesCustom()" >
        </mat-form-field>
    </div>

    <div class="ds-flex-column w-22">
        <mat-label class="font-16 text-white">Rango fecha:</mat-label>
        <mat-form-field appearance="fill">
            <div class="ds-flex-center-between">
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="DD-M-MYY">
                    <input matEndDate placeholder="DD-MM-YY">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </div>
        </mat-form-field>
    </div>
</form>

<div class="container-component">
    <div class="ds-flex-center-between text-gray margin-bottom-1">
        <div class="font-20">
            <span class="margin-right-04 font-600">{{this.detallePeticion.Tot_Regs}}</span>
            <span class="margin-right-04">Respuestas</span>
        </div>

        <div class="ds-flex font-18">
            <div class="ds-flex-column border-box-right padding-0-05">
                <div>
                    <span class="margin-right-04">OPR</span>
                    <span class="margin-right-04 font-600">{{this.detallePeticion.Total_Opr | currency: "MXN":"symbol-narrow"}}</span>
                </div>

                <div>
                    <span class="margin-right-04">SPEI</span>
                    <span class="margin-right-04 font-600">{{this.detallePeticion.Total_SPEI | currency: "MXN":"symbol-narrow"}}</span>
                </div>
            </div>

            <div class="ds-flex-column border-box-right padding-0-05">
                <div>
                    <span class="margin-right-04">...7699</span>
                    <span class="margin-right-04 font-600">{{this.detallePeticion.Total_7699 | currency: "MXN":"symbol-narrow"}}</span>
                </div>

                <div>
                    <span class="margin-right-04">...8004</span>
                    <span class="margin-right-04 font-600">{{this.detallePeticion.Total_8004 | currency: "MXN":"symbol-narrow"}}</span>
                </div>
            </div>

            <div class="ds-flex-column padding-0-05">
                <div>
                    <span class="margin-right-04">Total</span>
                    <span class="margin-right-04 font-600">{{this.detallePeticion.Tot_General | currency: "MXN":"symbol-narrow"}}</span>
                </div>
            </div>
        </div>
    </div>

    <section class="overflow-x">
        <table mat-table [dataSource]="dataSourceAll" class="table-content">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>

                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef class="w-col-1"> Fecha </th>
                <td mat-cell *matCellDef="let element"> {{element.Fecha}} </td>
            </ng-container>

            <ng-container matColumnDef="tipo">
                <th mat-header-cell *matHeaderCellDef class="w-col-2"> Tipo de pago </th>
                <td mat-cell *matCellDef="let element"> {{element.Tipo_Pago}} </td>
            </ng-container>

            <ng-container matColumnDef="cuenta">
                <th mat-header-cell *matHeaderCellDef class="w-col-3"> Cuenta retiro </th>
                <td mat-cell *matCellDef="let element"> {{element.Cuenta_Retiro}} </td>
            </ng-container>

            <ng-container matColumnDef="clave">
                <th mat-header-cell *matHeaderCellDef class="w-col-4"> Clave </th>
                <td mat-cell *matCellDef="let element"> {{element.Clave}} </td>
            </ng-container>

            <ng-container matColumnDef="tipoRetiro">
                <th mat-header-cell *matHeaderCellDef class="w-col-5"> Tipo de retiro </th>
                <td mat-cell *matCellDef="let element" class="border-right-2"> {{element.Tipo_Retiro}} </td>
            </ng-container>

            <ng-container matColumnDef="opEnviados">
                <th mat-header-cell *matHeaderCellDef class="w-col-6"> No. Op. </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.Num_Operaciones}} </td>
            </ng-container>

            <ng-container matColumnDef="montoEnviados">
                <th mat-header-cell *matHeaderCellDef class="w-col-7"> Monto </th>
                <td mat-cell *matCellDef="let element" class="border-right-2 text-end"> {{element.Monto_Total | currency: 'MXN':"symbol-narrow"}} </td>
            </ng-container>

            <ng-container matColumnDef="opAplicados">
                <th mat-header-cell *matHeaderCellDef class="w-col-8"> No. Op. Aprobadas</th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.Op_Aprobadas}} </td>
            </ng-container>

            <ng-container matColumnDef="montoAplicados">
                <th mat-header-cell *matHeaderCellDef class="w-col-9"> Monto </th>
                <td mat-cell *matCellDef="let element" class="border-right-2 text-end"> {{element.Monto_Operaciones_Aplicadas | currency: 'MXN':"symbol-narrow"}} </td>
            </ng-container>

            <ng-container matColumnDef="opNoAplicados">
                <th mat-header-cell *matHeaderCellDef class="w-col-10"> No. Op. Rechazadas</th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.Op_Rechazadas}} </td>
            </ng-container>

            <ng-container matColumnDef="montoNoAplicados">
                <th mat-header-cell *matHeaderCellDef class="w-col-11"> Monto </th>
                <td mat-cell *matCellDef="let element" class="border-right-2 text-end"> {{element.Monto_Operaciones_Rechazadas | currency: 'MXN':"symbol-narrow"}} </td>
            </ng-container>
s
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="">  </th>
                <td mat-cell *matCellDef="let element" class="border-right-2 text-end"> {{element.XML_Status_Envio}} </td>
            </ng-container>
 
            <ng-container matColumnDef="comprobante">
                <th mat-header-cell *matHeaderCellDef class="w-custom">  </th>
                <td mat-cell *matCellDef="let element" class="show-detail">
                    <div class="ds-flex">
                        <button class="ds-flex-align-center detail-button" (click)="showDetail(element)">
                            <img class="margin-right-05" src="../../../../../assets/img/icon/fi-rr-list-button.svg">
                            <div class="font-15 text-white">Ver detalle</div>
                        </button>
                        <button *ngIf="element.Tipo_Pago !== 'TRANSF'" class="ds-flex-align-center detail-button-pdf margin-right-05" (click)="openDialogPdf(element)">
                            <img class="margin-right-05" src="../../../../../assets/img/icon/fi-rr-download.svg">
                            <div class="font-15 text-white">.pdf</div>
                        </button>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" >
            </tr>
        </table>
    </section>
    <div class="ds-flex-between">
        <div class="ds-flex-end margin-top-1">
            <button class="button-blue-download ds-flex-centers" (click)="exportData()">
                <img class="margin-right-04" src="../../../../../assets/img/icon/fi-rr-grid-alt.svg">
                <div>Exportar .xls</div>
            </button>
        </div>

        <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>
    </div>
</div>