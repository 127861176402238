import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-logout-confirmation',
  templateUrl: './logout-confirmation.component.html',
  styleUrls: ['./logout-confirmation.component.scss']
})
export class LogoutConfirmationComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LogoutConfirmationComponent>,
    public router: Router,
    public route: ActivatedRoute,
    private dbService: NgxIndexedDBService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close(); 
  }

  logout() {
    this.onClose();
    localStorage.clear();
    sessionStorage.clear();
    this.authService.signOut();
    this.dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(['/login']);
    });
  }

}
