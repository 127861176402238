import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CatalogTable, UserTable } from '../modules/inbox/components/inbox/inbox.component';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ConsumeService } from './consume.services';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DataBaseService {
    constructor(private dbService: NgxIndexedDBService, private consumeService: ConsumeService) { }

    obtenerCatalogos(): Observable<any> {
        return new Observable<any>((observer) => {
            this.dbService.getByIndex('catalog','name','menu').subscribe( (dataCat:CatalogTable) => {  
                let result:CatalogTable;
                if(dataCat === undefined){
                    this.consumeService.obtenerCatalogos()
                    .subscribe( data => {
                    this.dbService
                    .add('catalog', {
                        name: 'menu',
                        value: data
                    }).subscribe((key) => {
                        result = dataCat;
                    });
                    }, err => console.error(err) );      
                }else{
                    result = dataCat;
                }
                observer.next(result);
            }, err => console.error(err) );
        });
    }

   obtenerUsuario(): Observable<any> {
    return new Observable<any>((observer) => {
            this.dbService.getByIndex('user','tipo','userLog').subscribe( (data:UserTable) => {  
                observer.next(data.user.username);
            }, err => console.error(err) );
        });
    }

    getInfoSession(): Observable<any> {
        return new Observable<any>((observer) => {
                this.dbService.getByIndex('user','tipo','userLog').subscribe( (data:UserTable) => {  
                    observer.next(data);
                }, err => console.error(err) );
            });
        }
}  