import { Injectable } from '@angular/core';
import { CognitoUser, Auth } from '@aws-amplify/auth';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Usuario } from '../model/usuario.model';
import { User } from '../model/user';
//import * as authActions from '../ReduxStore/actions/usuario.actions';
//import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router) { }
  
  isAuth = () => {
    return from(this.userPromise()).pipe(map((user) => user != null));
  }

  userPromise = () => {
    return new Promise(async (resolve) => {
      return await Auth.currentAuthenticatedUser()
        .then((result: CognitoUser) => {
          if (result.getSignInUserSession().isValid()) {
            resolve(result);
          } else {
            resolve(null);
          }
        })
        .catch(() => resolve(null));
    });
  }

  initAuthData = () => {
    Auth.currentAuthenticatedUser()
      .then(async (result: CognitoUser) => {
        if (result.getSignInUserSession().isValid()) {

          sessionStorage.setItem(
            'access',
            (await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()
              .toString()
          );

          sessionStorage.setItem(
            'accessRefresh',
            (await Auth.currentSession())
              .getRefreshToken()
              .getToken()
              .toString()
          );

          let idToken = (await Auth.currentSession()).getIdToken()

          
          const user = Usuario.fromAmplify(new User(result));

          if (!user.attributes.given_name) {
            user.attributes.given_name = user.name + ' - Guest';
          }

          localStorage.setItem(
            'user',
            user.attributes
          );


          localStorage.setItem(
            'userCustom',
            JSON.stringify(user.attributes)
          );
          ////this.store.dispatch(authActions.setUser({ user }));
          
          
        }
      })
      .catch(() => console.error('Error al autenticar') /*this.store.dispatch(authActions.unSetUser())*/);
  }

  signOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    this.cleanStates();
    this.router.navigate(['/login']).then(() => {

      setTimeout(() =>{
        window.location.reload();
      },10)

    });
  }

  cleanStates = () => {
  }

  /*
    Función que se encarga de refrescar el token a partir de los datos de la sesión actual
  */
    refreshToken = async () => {
      try {
        // obtener los datos de la sesión actual
        const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
        const currentSession = await Auth.currentSession();
        // realizar la peticion por medio del SDK para refrescar los tokens
        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
          const { idToken, refreshToken, accessToken } = session;
          // con los datos nuevos de la sesion actualizar los tokens en memoria
          this.initAuthData();
        });
      } catch (e) {
        // si ocurrio un error al tratar de refrescar los tokens, se forza el cierre de sesion
        this.signOut();
      }
    }

}
