import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { DataBaseService } from "../services/database.service";
import { SessionDetail } from "../modules/inbox/components/inbox/inbox.component";

@Injectable()
export class JwtInterceptor implements HttpInterceptor{
    constructor(public dataBase:DataBaseService){

    }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                'Authorization': sessionStorage.getItem('access')
            }
        });
        return next.handle(request).pipe(
            catchError(error => {
                let errorMessage = '';
                if(error instanceof ErrorEvent){
                    errorMessage = `Client-side error ${error.error.message}`;
                }else{
                    errorMessage = `Client-side error ${error.status} ${error.message}`;
                }
                return throwError(error);
            })
        );
    }

    getDetail():any{
        this.dataBase.getInfoSession().subscribe( data => {
            return data;
          }, err => console.error(err) );
    }
    
}