import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { CognitoUser, Auth } from '@aws-amplify/auth';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(private _router: Router, private authService: AuthService,
    private dbService: NgxIndexedDBService) {}

  canLoad():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.isAuth().pipe(
      tap((user: any) => {
        if (!user) {
          this._router.navigate(['/login']);
        }
      }),
      take(1)
    );
  }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return Auth.currentAuthenticatedUser()
      .then((result: CognitoUser) => {
        if (result.getSignInUserSession().isValid()) {
            this.dbService
            .add('user', {
              tipo: 'userLog',
              token: result.getSignInUserSession().getAccessToken().getJwtToken().toString(),
              refresh: result.getSignInUserSession().getRefreshToken().getToken().toString(),
              user: result.getSignInUserSession().getAccessToken().payload
            });
          return true;
        } else {
          this._router.navigate(['/login']);
          return false;
        }
      })
      .catch(() => {
        this._router.navigate(['/login']);
        return false;
      });
  }
}
