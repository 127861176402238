import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ConsumeService } from 'src/app/services/consume.services';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { Table } from 'pdfmake/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ErrorComponent } from 'src/app/modules/modals/error/error.component';
import { ModifyCountEmmiter } from 'src/app/modules/inbox/components/inbox/modify-count-emmiter';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit, AfterViewInit {

  selectedFiltrar = 'option1';
  fechaInicio:string="";
  fechaFin:string="";
  statisticsForm= new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  @ViewChild('MatPaginator') MatPaginator : MatPaginator;
  @ViewChild('MatPaginator2') MatPaginator2 : MatPaginator;
  isLoading: boolean = false;

  myFilter = (d: Date | null): boolean => {
    const date = d || new Date();
    return date < new Date();
  };

  constructor(
    private consumeService: ConsumeService,
    public dialog:MatDialog, public totalEmmit:ModifyCountEmmiter) {
        
    }
    ngAfterViewInit(): void {
        
    }

  columnNames:any;
  columnNamesRetiro:any;
  data : Array<any> = [];
  dataSucursal = new MatTableDataSource<any>();
  dataTipoRetiro : Array<any> = [];
  dataRetiro = new MatTableDataSource<any>();
  encabezado: string[] = [];
  encabezadoRetiro: string[] = [];
  estadistica =  {} as TableCustom;
  
  ngOnInit(): void {
    localStorage.setItem('tabIndexSet', '0');
    this.totalEmmit.ejecutaAccionMenus.emit('Inbox');
  }

  llenarSucursal(){
    //Mapeo de encabezados
    if(this.estadistica.estadisticasSucursales !== undefined &&
      this.estadistica.estadisticasSucursales.length > 0){
    this.encabezado.push('Sucursal');
    this.estadistica.estadisticasSucursales[0].oprs.forEach(oprs =>{
      oprs.mes.forEach(mOpr => {
        this.encabezado.push(mOpr.nombre+oprs.anio);
      });
    });
    this.encabezado.push("Total OPR");
    this.estadistica.estadisticasSucursales[0].trs.forEach(trs =>{
        trs.mes.forEach(mTrs => {
          this.encabezado.push(mTrs.nombre+trs.anio+' ');
        });
      });
    this.encabezado.push("Total TR");
    this.encabezado.push("% TR ULT MES");
    this.encabezado.push("% TR");
    //Fin mapeo de encabezados

    //Mapeo de filas
    this.estadistica.estadisticasSucursales.forEach(suc =>{
      let i = 0;
      let reg = '{"Sucursal":"'+suc.sucursal;
      suc.oprs.forEach(anio =>{
        anio.mes.forEach(mes =>{
        reg = reg +'","'+ mes.nombre+anio.anio+'":"'+mes.suma_x_mes;
        });
      });
      reg = reg + '","Total OPR":"'+suc.totalOprGrl;

      suc.trs.forEach(anio =>{
        anio.mes.forEach(mes =>{
        reg = reg +'","'+ mes.nombre+anio.anio+' '+'":"'+mes.suma_x_mes;
        });
      });
      reg = reg + '","Total TR":"'+suc.totalTrGrl;

      reg = reg + '","% TR ULT MES":"'+suc.porc_ult_mes;
      reg = reg + '","% TR":"'+suc.prc_suc_gral+'"}';
        this.data.push(JSON.parse(reg.toString()));
    });
    
    this.dataSucursal = new MatTableDataSource<any>(this.data);
    this.columnNames = this.encabezado;
  }
  }

  llenarRetiro(){
    //Mapeo de encabezados
    if(this.estadistica.estadisticasSucursales !== undefined && 
      this.estadistica.estadisticasSucursales.length > 0){
    this.encabezadoRetiro.push('Tipo de Retiro');
    this.estadistica.estadisticasTipoRetiro[0].oprs.forEach(oprs =>{
      oprs.mes.forEach(mOpr => {
        this.encabezadoRetiro.push(mOpr.nombre+oprs.anio);
      });
    });
    this.encabezadoRetiro.push("Total OPR");
    
    this.estadistica.estadisticasTipoRetiro[0].trs.forEach(trs =>{
      trs.mes.forEach(mTrs => {
        this.encabezadoRetiro.push(mTrs.nombre+trs.anio+' ');
      });
    });
    this.encabezadoRetiro.push("Total TR");

    this.encabezadoRetiro.push("% TR ULT MES");
    this.encabezadoRetiro.push("% TR");
    //Fin mapeo de encabezados

    //Mapeo de filas
    this.estadistica.estadisticasTipoRetiro.forEach(suc =>{
      let i = 0;
      let reg = '{"Tipo de Retiro":"'+suc.tipo_retiro;
      suc.oprs.forEach(anio =>{
        anio.mes.forEach(mes =>{
        reg = reg +'","'+ mes.nombre+anio.anio+'":"'+mes.suma_x_mes;
        });
      });
      reg = reg + '","Total OPR":"'+suc.totalOprGrl;

      suc.trs.forEach(anio =>{
        anio.mes.forEach(mes =>{
        reg = reg +'","'+ mes.nombre+anio.anio+' '+'":"'+mes.suma_x_mes;
        });
      });
      reg = reg + '","Total TR":"'+suc.totalTrGrl;


      reg = reg + '","% TR ULT MES":"'+suc.porc_ult_mes;
      reg = reg + '","% TR":"'+suc.prc_suc_gral+'"}';
        this.dataTipoRetiro.push(JSON.parse(reg.toString()));
    });
    
    this.dataRetiro = new MatTableDataSource<any>(this.dataTipoRetiro);
    this.columnNamesRetiro = this.encabezadoRetiro;
  }
  }

  search(){
    if(this.statisticsForm.controls.end.value === null || this.statisticsForm.controls.start.value === null){
        this.dialog.open(ErrorComponent,{data:{error:"Seleccione un rango de fechas valido."}});
    }else{
        this.isLoading = true;
        let separator = '-';
        let date = new Date(this.statisticsForm.controls.end.value);
        let month = this.addCeroLeft(date.getMonth() + 1);
        let day = this.addCeroLeft(date.getDate())
        let dateStr = `${date.getFullYear()}${separator}${month}${separator}${day}`;
        this.fechaFin = dateStr;
    
        let dateIni = new Date(this.statisticsForm.controls.start.value);
        let monthIni = this.addCeroLeft(dateIni.getMonth() + 1);
        let dayIni = this.addCeroLeft(dateIni.getDate())
        let dateStrIni = `${dateIni.getFullYear()}${separator}${monthIni}${separator}${dayIni}`;
        this.fechaInicio = dateStrIni;
        this.encabezado = [];
        this.encabezadoRetiro = [];
        this.dataSucursal = new MatTableDataSource<any>();
        this.dataRetiro = new MatTableDataSource<any>();
        this.dataTipoRetiro =  []
        this.data = [];
        this.columnNames = [];
        this.columnNamesRetiro = [];
        this.estadistica =  {} as TableCustom;
    
        this.consumeService.obtenerEstadistica(this.fechaInicio, this.fechaFin)
        .subscribe( (data:TableCustom) => {
            if(data !== undefined){
                this.estadistica = data;
                this.llenarSucursal();
                this.llenarRetiro();
                this.dataSucursal.paginator = this.MatPaginator;
                this.dataRetiro.paginator = this.MatPaginator2;
                this.isLoading = false;
            }
            this.isLoading = false;
            }, err => {
              this.isLoading = false;
            }
        );
    }
    
  }

  addCeroLeft(num: number):string{
    return num.toString().length === 1 ? `0${num}` : `${num}`;
  }

  exportSucursal():void{
    if(this.statisticsForm.controls.end.value === null || this.statisticsForm.controls.start.value === null){
        this.dialog.open(ErrorComponent,{data:{error:"Seleccione un rango de fechas valido."}});
    }else{
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(this.data);
        XLSX.utils.book_append_sheet(wb, ws, 'Sucrusales');
        XLSX.writeFile(wb, `${'Sucursales'}.xlsx`);
    }
  }

  exportTr():void{
    if(this.statisticsForm.controls.end.value === null || this.statisticsForm.controls.start.value === null){
        this.dialog.open(ErrorComponent,{data:{error:"Seleccione un rango de fechas valido."}});
    }else{
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(this.dataTipoRetiro);
        XLSX.utils.book_append_sheet(wb, ws, 'TR');
        XLSX.writeFile(wb, `${'TipoRetiro'}.xlsx`);
    }
  }

  
}

export interface SendOpr {
  oficinaOpr: string;
  mesUnoOpr: number;
  mesDosOpr: number;
  mesTresOpr: number;
  mesCuatroOpr: number;
  total: number;
}

export interface SendTr {
  mesUnoTr: number;
  mesDosTr: number;
  mesTresTr: number;
  mesCuatroTr: number;
  total: number;
  num: number;
}

export interface SendMesTr {
  porcentajeTr: string;
}

export interface SendPorcenTr {
  porcentajeTr: string;
}


export interface  TableCustom {
    estadisticasSucursales:EstadisticasOpr[];
    estadisticasTipoRetiro:EstadisticasTr[];
}

export interface EstadisticasOpr{
    sucursal: string;
    porc_ult_mes: string;
    prc_suc_gral: string;
    totalOprGrl: string;
    totalTrGrl: string;
    trs: DetalleEstadistica[];
    oprs: DetalleEstadistica[];
}

export interface EstadisticasTr{
    tipo_retiro: string;
    porc_ult_mes: string;
    prc_suc_gral: string;
    totalOprGrl: string;
    totalTrGrl: string;
    trs: DetalleEstadistica[];
    oprs: DetalleEstadistica[];
}

export interface DetalleEstadistica{
    anio:string;
    total_anual:string;
    mes:MesEstadistica[];

}

export interface MesEstadistica{
    nombre:string;
    suma_x_mes:string;
}