export const environment = {
  production: true,
  tiempoRefrescar: '3600000',
  sesionValida: '8',
  SESConfig: {
    hthCognitoIdentityPoolId: 'us-east-1:4034f461-887b-450b-ac10-203ee93a515a',
    region: 'us-east-1',
  },
  urlExternalLogin: 'https://hth-user-pool-develop.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Azure&redirect_uri=https://web.hosttohostafore.service.dev.principal.com.mx/&response_type=code&client_id=1h1qbhmkv8tbpmv1dq1afkno9o&scope=phone%20email%20openid%20profile',
  amplifyConfig: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_O22COVhug',
      userPoolWebClientId: '1h1qbhmkv8tbpmv1dq1afkno9o',
      limit: 50,
      oauth: {
        domain: 'hth-user-pool-develop.auth.us-east-1.amazoncognito.com',
        scope: ['phone', 'email', 'openid', 'profile'],
        redirectSignIn: 'https://web.hosttohostafore.service.dev.principal.com.mx/',
        redirectSignOut: 'https://web.hosttohostafore.service.dev.principal.com.mx/login',
        responseType: 'code'
      },
    }
  },
  apigateway: 'https://api.hosttohostafore.service.dev.principal.com.mx/hosttohost'
  };
